<template>
  <v-main class="text-center">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <!-- <v-img
            max-height="150"
            max-width="250"
            src="./../assets/logo.png"
          ></v-img> -->

          <img
            class="cover mb-5"
            width="300"
            src="./../assets/logo.png"
            alt=""
          />
          <h3>Maaf Anda bukan admin...</h3>
          <h5>Silakan Logout</h5>

          <v-btn @click="dialogConfirm = true" large depressed color="error">
            <v-icon aria-hidden="false"> mdi-power </v-icon> Logout
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogConfirm" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">warning!</span>
        </v-card-title>
        <v-card-text> Anda yakin ingin keluar? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogConfirm = false">
            Cancel
          </v-btn>
          <v-btn @click="signOut()" color="red darken-1" text> Log Out </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();

export default {
  name: "Dashboard",
  data() {
    return {
      dialogConfirm: false,
    };
  },

  methods: {
    signOut() {
      try {
        signOut(auth)
          .then(() => {
            // console.log("logout ", result);
            this.dialogConfirm = false;
            // Sign-out successful.
          })
          .catch((error) => {
            // An error happened.
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
